import React, { useState, useEffect } from "react";


import api from "../../services/api";
import "../../assets/css/style.css"
import createErrorMessage from "../../utils/createErrorMessage"
import Myframe from "../Myframe";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { Col, Row } from "react-bootstrap";
import SelectLabels from "../../components/SelectLabels";
import DATATABLE from "../../components/DataTable/dataTable";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Actions from "../../components/Actions";
import validator from "../../services/validator.service";
import ConfirmBox from "../../components/ConfirmBox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { capFirstLetter } from "../../utils/string";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import { jsPDF } from "jspdf";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Preview, print } from "react-html2pdf";
import StringMessage from "../../utils/string-messages";
import AddButton from "../../components/AddButton";
import angola_logo from '../../assets/images/angola.png';
import DualBoxList from "../../components/DualBoxList";
import html2canvas from "html2canvas";
import Select from "@mui/material/Select";
import { formatDateReverse, formatDateDocument, formatDateInput, formatDateInput2 } from "../../utils/formatDate";
import { compareNullDate, compareNullDateFormatted } from "../../utils/compareNullDate";
import { compareIndex, compareCode, compareCodeIndex } from "../../utils/compareIndex";
import QRCode from 'qrcode';

const userApiService = new api();
const columns = [
  {
    label: "#",
    field: "num",
    sort: "asc",
    width: 10,
  },
  {
    label: "Entidades",
    field: "nome",
    sort: "asc",
    width: 300,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 300,
  },
  {
    label: "NIF",
    field: "nif",
    sort: "asc",
    width: 300,
  },
  {
    label: "Contacto",
    field: "contacto",
    sort: "asc",
    width: 300,
  },
  {
    label: "Província",
    field: "provincia",
    sort: "asc",
    width: 300,
  },
  {
    label: "Município",
    field: "municipio",
    sort: "asc",
    width: 300,
  },
  {
    label: "Entidade Superior",
    field: "entidade",
    sort: "asc",
    width: 300,
  },
  {
    label: "Acções",
    field: "action",
    sort: "asc",
    width: 200,
  },
];

const validation = new validator();

export default function Entidades(props) {
  const [list, setList] = useState([]);
  const [listSuperiorEntities, setListSuperiorEntities] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState("Formulário");
  const [openForm, setOpenForm] = useState(0);
  const [codigo, setCodigo] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [nif, setNif] = useState("");
  const [provincia, setProvincia] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [comuna, setComuna] = useState("");
  const [aldeiaBairro, setAldeiaBairro] = useState("");
  const [contacto, setContacto] = useState("");
  const [observacao, setObservacao] = useState("");
  const [objectoSocial, setObjectoSocial] = useState("");
  const [tipoExploracao, setTipoExploracao] = useState("");
  const [tipoInterna, setTipoInterna] = useState("interna");
  const [tipoEntidade, setTipoEntidade] = useState("externa");
  const [tipoEploracForm, setTipoExploraForm] = useState("");
  const [declarou, setDeclarou] = useState(false);
  const [tipoEploraPrestador, setTipoExploraPrestador] = useState("");
  const [pequenoProdutor, setPequenoProdutor] = useState("");
  const [back, setBack] = useState("");
  const [cancel, setCancel] = useState("");
  const [listaProvs, setListaProvs] = useState([
    { codigo: null, nome: "Selecionar Província", sigla: "" },
  ]);
  const [listaMunics, setListaMunics] = useState([
    { codigo: null, nome: "Selecionar Município" },
  ]);
  const [listaComunas, setListaComunas] = useState([
    { codigo: null, nome: "Selecionar Comuna" },
  ]);
  const [listaAldeiaBairros, setListaAldeiaBairros] = useState([
    { codigo: null, nome: "Selecionar Aldeia/Bairro" },
  ]);
  const [listaExploracao, setListaExploracao] = useState([]);
  const [listaObjectoSocial, setListaObjectoSocial] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [openItemForm, setNewItemOpen] = useState(false);
  const [prov, setProv] = useState("");
  const [provName, setProvName] = useState("");
  const [provSigla, setProvSigla] = useState("");
  const [isMun, setIsMun] = useState(false);
  const [licencaDeExploracao, setLicencaDeExploracao] = useState("");
  const [tipoProducao, setTipoDeProducao] = useState()
  const [productionCapacity, setProductionCapacity] = useState();
  const [occupiedCompanyTotalArea, setOccupiedCompanyTotalArea] = useState('');
  const [cultiviedCompanyTotalArea, setCultivatedCompanyTotalArea] = useState('');
  const [managerName, setManagerName] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const [managerContact, setManagerContact] = useState(0);
  const [companyInfrastucture, setCompanyInfrastructure] = useState(0);
  const [numberOfMaleEmployee, setNumberOfMaleEmployee] = useState(0);
  const [numberOfFemaleEmployee, setNumberOfFemaleEmployee] = useState(0);
  const [titleNumberOfLandConception, setTitleOfLandConception] = useState('');
  const [staticRegister, setStaticRegister] = useState('');
  const [registerNumber, setRegisterNumber] = useState(null);
  const [companyCreatedDate, setCompanyCreatedDate] = useState('')
  const [dataRegistro, setDataRegistro] = useState('');
  const [dataRegistroDocumento, setDataRegistroDocumento] = useState('');
  // const [entidadeSuperior, setEntidadeSuperior] = useState("Sistema");
  const [entidadeSuperior, setEntidadeSuperior] = useState({ codigo: 1, nome: "Sistema" });
  const [selectedFormularios, setSelectedFormularios] = useState([]);
  const [selectedMainActivities, setSelectedMainActivities] = useState([]);
  const [selectedProductionTypes, setSelectedProductionTypes] = useState([]);
  const [formularioOptions, setFormularioOptions] = useState([]);
  const [filteredFormularioOptions, setFilteredFormularioOptions] = useState([]);
  const [filteredMainActivitiesOptions, setFilteredMainActivitiesOptions] = useState([]);
  const [filteredProductionTypeOptions, setFilteredProductionTypeOptions] = useState([]);
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [listaFormularios, setListFormularios] = useState([]);
  const [filiationNumber, setFiliationNumber] = useState("");
  const [registeredBy, setRegisteredBy] = useState("");

  const [listaFilteredMunics, setListaFilteredMunics] = useState([
    { codigo: null, nome: "Selecionar Município" },
  ]);
  const [listaTipoProducao, setListaTipoProducao] = useState([
    { codigo: null, nome: "Selecionar Tipo de Produção" },
  ]);
  const [listaTipoCultura, setListaTipoCultura] = useState([
    { codigo: null, nome: "Selecionar Tipo de Culturas" },
  ]);
  const [listaEfectivoPecuario, setListaEfectivoPecuario] = useState([
    { codigo: null, nome: "Selecionar Efectivo Pecuario" },
  ]);
  const [listaCapacidadeProducao, setListaCapacidadeProducao] = useState([
    { codigo: null, productionsCapacity: "Selecionar Capacidade de Produção" },
  ]);
  const [listaEntidadeSuperior, setListaEntidadeSuperior] = useState([
    { codigo: null, nome: "Selecionar Entidade Superior" },
  ]);
  const [listaFilteredComunas, setListaFilteredComunas] = useState([
    { codigo: null, nome: "Selecionar Comuna" },
  ]);
  const [listaFilteredAldeias, setListaFilteredAldeias] = useState([
    { codigo: null, nome: "Selecionar Aldeia/Bairro" },
  ]);
  const [listaActividadePrincipal, setListaActividadePrincipal] = useState([
    { codigo: null, nome: "Selecionar Actividade Principal" },
  ]);
  const [listaCompanyInfrasstructure, setListaCompanyInfrasstructure] = useState([
    { codigo: null, nome: "Selecionar Infra Estrutura de Empresa" },
  ]);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [tiposDeProducaoList, setTiposDeProducaoList] = useState([]);
  const [tiposDeCulturaList, setTiposDeCulturaList] = useState([]);
  const [tiposDeCulturaListCheck, setTiposDeCulturaListCheck] = useState([]);
  const [infraCompanyList, setInfraCompanyList] = useState([]);
  const [efectivoPecuarioList, setEfectivoPecuarioList] = useState([]);
  const [actividadePrincipalList, setActividadePrincipalList] = useState([]);
  const [nomeDirectorCong, setNomeDirectorCong] = useState([]);
  const [currentEntidade, setCurrentEntidade] = useState({});
  const [areaExploracaoCurso, setAreaExploracaoCurso] = useState(0);
  const [sistemFormRecibData, setSistemFormRecibData] = useState([]);
  const [addNewItemPM, setAddNewItemPM] = useState([]);
  const [listaComunaTemp, setListaComunaTemp] = useState([]);
  const [listaAldBairroTemp, setListaAldBairroTemp] = useState([]);
  const [listaMunicipiosTemp, setListaMunicipiosTemp] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  useEffect(async () => {
    setMunicipalitiesByProvince(provincia?.codigo);
  }, [provincia]);

  useEffect(async () => {
    setComunaByMunicepaloites(municipio?.codigo);
  }, [municipio]);

  useEffect(async () => {
    setAldeiaBairroByComuna(comuna?.codigo)
  }, [comuna]);

  useEffect(async () => {
    try {
      setOpenSpinner(true);
      validation.setShowMsg(showMsg.error);

      loadTable();
      let provs = (await userApiService.getProvinces()) ?? [];
      let munic = (await userApiService.getMunicipios()) ?? [];
      let comun = (await userApiService.getComunas()) ?? [];
      let bairr = (await userApiService.getBairros()) ?? [];
      let socialObj = (await userApiService.getSocialObjects()) ?? [];
      let explor = (await userApiService.getExplorationTypes()) ?? [];
      let formsList = await userApiService.getForms() || [];
      let tipoProdutor = (await userApiService.getProducersType()) ?? [];
      let tipoCultura = (await userApiService.getTipoCulturas()) ?? [];
      let activitiesList = (await userApiService.getAtividadesPrincipais()) ?? [];
      let tiposDeProducao = (await userApiService.getProducersType()) ?? [];
      let infrastructure_company = (await userApiService.getIfraEmpresa()) ?? [];
      let efectivosPecuario = (await userApiService.getEfectivoPecuario()) ?? [];
      let capacidadeProducao = []

      let nomeDirectorConfig = (await userApiService.getNameDirector()) ?? [];

      console.log('activitiesList', activitiesList)

      permissionHandle(provs, 'Provincia')
      permissionHandle(munic, 'Municipio')
      permissionHandle(comun, 'Comuna')
      permissionHandle(bairr, 'Bairro')
      permissionHandle(socialObj, 'Objecto Social')
      permissionHandle(explor, 'Tipo de Exploração')
      permissionHandle(formsList, 'Questionario')
      permissionHandle(tipoProdutor, 'Produtor')
      permissionHandle(tipoCultura, 'Cultura')
      permissionHandle(activitiesList, 'Actividades Principais')
      permissionHandle(tiposDeProducao, 'Perfil')
      permissionHandle(infrastructure_company, 'Infrastructure da Empresa')
      permissionHandle(efectivosPecuario, 'Efectivo Pecuario')

      if (Array.isArray(formsList)) {
        formsList = formsList.filter((form) => form.tipo === "formulario" || !form.tipo)
      } else {
        formsList = []
      }

      if (Array.isArray(provs)) {
        provs = provs.map((item) => {
          return { codigo: item.codigo, nome: item.nome, sigla: item.sigla };
        });
      } else {
        provs = []
      }

      if (Array.isArray(tipoProdutor)) {
        tipoProdutor = tipoProdutor.map((item) => {
          return {
            codigo: item?.codigo,
            nome: item?.nome,
          };
        });
      } else {
        tipoProdutor = []
      }

      if (Array.isArray(tipoCultura)) {
        tipoCultura = tipoCultura.map((item) => {
          return {
            codigo: item?.codigo,
            nome: item?.nome,
          };
        });
      } else {
        tipoCultura = []
      }

      if (Array.isArray(efectivosPecuario)) {
        efectivosPecuario = efectivosPecuario.map((item) => {
          return {
            codigo: item?.codigo,
            nome: item?.nome,
          };
        });
      } else {
        efectivosPecuario = []
      }

      if (Array.isArray(tipoProdutor)) {
        capacidadeProducao = tipoProdutor.map((item) => {
          return {
            codigo: item?.codigo,
            nome: item?.productionsCapacity,
          };
        });
      } else {
        tipoProdutor = []
      }

      if (Array.isArray(munic)) {
        munic = munic.map((item) => {
          return {
            codigo: item.codigo,
            nome: item.nome,
            provincia: item.provincia.codigo,
          };
        });
      } else {
        munic = []
      }

      if (Array.isArray(comun)) {
        comun = comun.map((item) => {
          return {
            codigo: item.codigo,
            nome: item.nome,
            municipio: item.municipio.codigo,
          };
        });
      } else {
        comun = []
      }

      if (Array.isArray(infrastructure_company)) {
        infrastructure_company = Object.values(infrastructure_company).map((item) => {
          return {
            codigo: item?.codigo,
            nome: item?.nome,
            infrastructure_company: item?.codigo,
          };
        });
      } else {
        infrastructure_company = []
      }

      if (Array.isArray(bairr)) {
        bairr = bairr.map((item) => {
          return {
            codigo: item.codigo,
            nome: item.nome,
            comuna: item.comuna.codigo,
          };
        });
      } else {
        bairr = []
      }

      if (Array.isArray(formsList)) {
        formsList = formsList.map((item) => {
          return { value: item.codigo, label: item.nome, models: item.models };
        });
      } else {
        formsList = []
      }

      if (Array.isArray(activitiesList)) {
        activitiesList = activitiesList.map((item) => {
          // return { value: item.codigo, label: item.nome, models: item.models };
          return { codigo: item.codigo, nome: item.nome };
        });
      } else {
        activitiesList = []
      }

      if (Array.isArray(tiposDeProducao)) {
        tiposDeProducao = tiposDeProducao.map((item) => {
          return { value: item.codigo, label: item.nome, models: item.models };
        });
      } else {
        tiposDeProducao = []
      }

      //look
      //setFilteredFormularioOptions(formsList);
      setListaProvs([listaProvs[0], ...provs]);
      setListaMunics([listaMunics[0], ...munic]);
      setListaComunas([listaComunas[0], ...comun]);
      setListaAldeiaBairros([listaAldeiaBairros[0], ...bairr]);
      setListaObjectoSocial(socialObj);
      setListaEntidadeSuperior();
      setListaExploracao(explor);
      setFormularioOptions(formsList);
      setFilteredMainActivitiesOptions(activitiesList);
      setFilteredProductionTypeOptions(tiposDeProducao);
      setListaActividadePrincipal([listaActividadePrincipal[0], ...activitiesList]);
      setListaTipoProducao([listaTipoProducao[0], ...tipoProdutor]);
      setListaTipoCultura([listaTipoCultura[0], ...tipoCultura]);
      setListaEfectivoPecuario([listaEfectivoPecuario[0], ...efectivosPecuario])
      setListaCapacidadeProducao([listaCapacidadeProducao[0], ...capacidadeProducao])
      setListaCompanyInfrasstructure([listaCompanyInfrasstructure[0], ...infrastructure_company])


      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
    } catch (error) {
      console.log('catch error: ', error);
      if (error.status === 403) showMsg.error(error.message);
      setOpenSpinner(false);
    }
  }, []);

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  let sistemaForm = [];

  let addItemsPm = [];

  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  const hasCultureType = (culture) => {
    for (let index in tiposDeCulturaList) {
      if (culture.codigo === tiposDeCulturaList[index].codigo) return true;
    }

    return false;
  };

  hideSpinner();

  const setData = async (entidade = null) => {

    try {
      console.log("setData", entidade);
      var tipoEntity = entidade?.tipo_entidade || "interna";

      setTipoInterna(tipoEntity);
      setTipoEntidade(tipoEntity);

      if (tipoEntity === 'interna') {
        setTipoExploraPrestador('');
        setPequenoProdutor("")
        setTipoExploraForm("")
      } else if (tipoEntity === 'externa') {
        console.error('ola estou aquii', tipoEntity);
        setTipoExploraForm(entidade?.empresarial)
        setTipoExploraPrestador(entidade?.prestador)
        setPequenoProdutor(entidade?.pequeno_produtor)
      }

      setCodigo(entidade?.codigo);
      setNome(entidade?.nome || "");
      setContacto(entidade?.contacto || "");
      setNif(entidade?.nif);
      setEmail(entidade?.email || "");
      // setLicencaDeExploracao(entidade?.licenca_de_exploracao);
      setObservacao(entidade?.observacao);
      setObjectoSocial(entidade?.objecto_social);
      setRegisterNumber(entidade?.number_minagrip_register);
      setRegisteredBy(entidade?.number_minagrip_register.split('/')[1] || 'MINAGRIF');
      setFiliationNumber(entidade?.filiation_number);
      setTipoExploracao(entidade?.tipo_de_exploracao);
      setEntidadeSuperior(entidade?.entidade_superior);
      setListFormularios([...new Set(entidade?.formularios)]);
      setSelectedFormularios(entidade?.formularios?.map((item) => item.codigo));
      setSelectedMainActivities(entidade?.main_activities?.map((item) => item.codigo));

      setProvincia(entidade?.provincia || "");
      setMunicipio(entidade?.municipio || "");
      setComuna(entidade?.comuna || "");
      setAldeiaBairro(entidade?.aldeia_bairro || "");
      setCompanyCreatedDate(formatDateReverse(entidade?.company_creation_date))
      setCultivatedCompanyTotalArea(entidade?.total_area_cultivated_company)
      setOccupiedCompanyTotalArea(entidade?.total_area_ocuppied_company)
      setRegisterNumber(entidade?.number_minagrip_register)
      setRegisteredBy(entidade?.number_minagrip_register.split('/')[1] || 'MINAGRIF');

      setManagerName(entidade?.manager_name)
      setManagerEmail(entidade?.manager_email)
      setManagerContact(entidade?.manager_contact)
      setNumberOfMaleEmployee(entidade?.male_number_employee)
      setNumberOfFemaleEmployee(entidade?.female_number_employee)
      setStaticRegister(entidade?.number_static_register)
      setTitleOfLandConception(entidade?.number_title_land_conception)
      setEfectivoPecuarioList([...new Set(entidade?.live_stock)])
      setInfraCompanyList([...new Set(entidade?.company_infrastructure)])
      setTiposDeCulturaList([...new Set(entidade?.culture_types)])
      setTiposDeProducaoList([...new Set(entidade?.production_types)])
      setDataRegistro(formatDateReverse(compareNullDateFormatted(entidade?.data_do_registro)))
      setDataRegistroDocumento(formatDateDocument(compareNullDateFormatted(entidade?.data_do_registro)))
      setAreaExploracaoCurso(entidade?.area_exploracao_curso || 0)
      setCurrentEntidade(entidade);
      setActividadePrincipalList([...new Set(entidade?.main_activities)])

      let filteredFormularios = (await userApiService.getForms()) ?? []
      let filteredMainActivities = (await userApiService.getAtividadesPrincipais()) ?? []
      let filteredProductionTypes = (await userApiService.getProducersType()) ?? []

      permissionHandle(filteredFormularios, 'Formularios')
      permissionHandle(filteredMainActivities, 'Actividades Principais')
      permissionHandle(filteredProductionTypes, 'Tipos de Produção')

      if (Array.isArray(filteredFormularios)) {
        filteredFormularios = filteredFormularios.map(
          (item) => {
            return { value: item.codigo, label: item.nome, models: item.models };
          }
        );
      } else {
        filteredFormularios = []
      }
      // setSistemaForm(filteredFormularios)



      sistemaForm = filteredFormularios
      setSistemFormRecibData(sistemaForm)

      if (Array.isArray(filteredMainActivities)) {
        filteredMainActivities = filteredMainActivities.map(
          (item) => {
            return { value: item.codigo, label: item.nome, models: item.models };
          }
        );
      } else {
        filteredMainActivities = []
      }


      if (Array.isArray(filteredProductionTypes)) {
        filteredProductionTypes = filteredProductionTypes.map(
          (item) => {
            return { value: item.codigo, label: item.nome, models: item.models };
          }
        );
      } else {
        filteredProductionTypes = []
      }

      if (tipoEntity === "externa" || entidade?.entidade_superior) {
        filteredFormularios = filteredFormularios.filter((item) =>
          item?.models.some(
            (item2) =>
              item2?.modelo === "entidade" &&
              item2?.model_id === entidade?.entidade_superior.codigo
          )
        );
      }

      if (entidade?.entidade_superior?.nome && entidade?.entidade_superior?.nome !== 'Sistema') {
        setFilteredFormularioOptions(filteredFormularios)
      } else if (entidade?.entidade_superior?.nome === 'Sistema') {

        if (sistemaForm !== undefined) setFilteredFormularioOptions(sistemaForm)
      } else {
        setFilteredFormularioOptions([]);
      }

      //setFilteredFormularioOptions(filteredFormularios);
      setFilteredMainActivitiesOptions(filteredMainActivities);
      setFilteredProductionTypeOptions(filteredProductionTypes);


      if (entidade?.provincia) {
        setListaFilteredMunics(
          listaMunics.filter(
            (model) =>
              model.codigo === null ||
              model.provincia === entidade?.provincia?.codigo
          )
        );
      }
      setMunicipio(entidade?.municipio);

      if (entidade?.municipio) {
        setListaFilteredComunas(
          listaComunas.filter(
            (model) =>
              model.codigo === null ||
              model.municipio === entidade?.municipio?.codigo
          )
        );
      }
      setComuna(entidade?.comuna);

      if (entidade?.comuna) {
        setListaFilteredAldeias(
          listaAldeiaBairros.filter(
            (model) =>
              model.codigo === null || model.comuna === entidade?.comuna?.codigo
          )
        );
      }
      setAldeiaBairro(entidade?.aldeia_bairro);

      let nomeDirectorConfig = (await userApiService.getNameDirector()) ?? [];
      setNomeDirectorCong(nomeDirectorConfig[0]?.nameDirector)

    } catch (error) {
      console.error('error', error);
    }
  };

  const onFormularioSelected = (selected) => {
    setSelectedFormularios(selected);
  };
  const onMainActivitySelected = (selected) => {
    setSelectedMainActivities(selected);
  };
  const onProductionTypesSelected = (selected) => {
    setSelectedProductionTypes(selected);
  };
  const handleSetTipoProducao = (obj) => {
    setTipoDeProducao(obj);
    setListaTipoProducao(
      listaTipoProducao.filter(
        (model) => model.codigo === null || model.tipoProdutor === obj.codigo
      )
    );
    setListaTipoProducao([
      { codigo: null, nome: "Selecionar Tipo de Produção" },
    ]);
  };
  const handleSetCapacityProductions = (obj) => {
    setProductionCapacity(obj);
    setListaCapacidadeProducao(
      listaCapacidadeProducao.filter(
        (model) => model.codigo === null || model.productionCapacity === obj.codigo
      )
    );
    setListaCapacidadeProducao([
      { codigo: null, nome: "Selecionar Capacidade de Produção" }])

    setListaActividadePrincipal([
      { codigo: null, nome: "Selecionar Actividade Principal" },
    ]);
  };
  const handleSetCompanyInfra = (obj) => {
    setListaCompanyInfrasstructure(obj);
    setListaCompanyInfrasstructure(
      listaCompanyInfrasstructure.filter(
        (model) => model.codigo === null || model.companyInfrastucture === obj.codigo
      )
    );
    setListaCompanyInfrasstructure([
      { codigo: null, nome: "Selecionar Infra Estrutura da Empresa" },
    ]);
  };
  const handleAdd = () => {
    setData();
    setDataRegistro(formatDateInput(new Date()))
    setOpenForm(1);
    setCancel("Cancelar");
    setEditButtonVisible("none");
    setSaveButtonVisible("flex");
    setTimeout(() => {
      const listagem = document.querySelector('.listagem')
      listagem.classList.remove('d-none')
      clearTimeout();
    }, 20);
  };

  const handleShow = (entidade) => {
    setData(entidade);
    setBack('Voltar');
    setCancel("Cancelar");
    setSaveButtonVisible('none')
    setOpenForm(2);
  };

  const handleEdit = async (entidade) => {
    showForm(entidade);
    setData(entidade);
    setCancel("Cancelar");

    setTimeout(() => {
      const listagem = document.querySelector('.listagem')
      listagem.classList.remove('d-none')
      clearTimeout();
    }, 1);
    setOpenForm(3);
    setEditButtonVisible("flex");
    setSaveButtonVisible("none");

    let filteredFormularios = (await userApiService.getForms()) ?? [];
    filteredFormularios = filteredFormularios.map((item) => {
      return { value: item.codigo, label: item.nome, models: item.models };
    });

    let filteredMainActivities = (await userApiService.getAtividadesPrincipais()) ?? [];
    filteredMainActivities = filteredMainActivities.map((item) => {
      return { value: item.codigo, label: item.nome, models: item.models };
    });

    let filteredProductionTypes = (await userApiService.getProducersType()) ?? [];
    filteredProductionTypes = filteredProductionTypes.map((item) => {
      return { value: item.codigo, label: item.nome, models: item.models };
    });

    setFormularioOptions(filteredFormularios);
    //setFilteredFormularioOptions(filteredFormularios);
    setFilteredMainActivitiesOptions(filteredMainActivities);
    setFilteredProductionTypeOptions(filteredProductionTypes);

  };

  const handleDelete = (obj) => {
    setCodigo(obj?.codigo);
    setShowConfirm(true);
  };

  const handleSetEntidadeSuperior = (obj) => {
    setEntidadeSuperior(obj);
    var filteredFormularios = formularioOptions.filter((item) =>
      item?.models.some(
        (item2) =>
          item2?.modelo === "entidade" && item2?.model_id === obj?.codigo
      )
    );
    if (obj?.nome && obj?.nome !== 'Sistema') {
      setFilteredFormularioOptions(filteredFormularios)
    } else if (obj?.nome === 'Sistema') {
      setFilteredFormularioOptions(sistemFormRecibData)
      // setFilteredFormularioOptions(sistemaForm)

    } else {
      setFilteredFormularioOptions([]);
    }

  };

  const handleProviaUpdate = (event) => {
    if (event) setProvincia(event);

  }

  const handleMunicipUpdate = (event) => {
    setMunicipio(event);
  }

  const handleComunaUpdate = (event) => {
    setComuna(event);
  }

  const setMunicipalitiesByProvince = (prov = null) => {
    if (listaMunics) {
      const aux = listaMunics.filter((obj) => {
        if (obj?.provincia === prov) return obj?.nome;
      })
      setListaMunicipiosTemp(aux)

    }
  }

  const setComunaByMunicepaloites = (prov = null) => {
    if (listaComunas) {
      const aux = listaComunas.filter((obj) => {
        if (obj?.municipio === prov) return obj?.nome;
      })
      setListaComunaTemp(aux)
    }
  }
  const setAldeiaBairroByComuna = (prov = null) => {
    if (listaAldeiaBairros) {
      const aux = listaAldeiaBairros.filter((obj) => {
        if (obj?.comuna === prov) return obj?.nome;
      })
      setListaAldBairroTemp(aux)
    }
  }

  const handleSetComuna = (obj) => {
    setComuna(obj);
    setListaFilteredAldeias(
      listaAldeiaBairros.filter(
        (model) => model.codigo === null || model.comuna === obj.codigo
      )
    );
  };
  const handleSetEfectivoPecuario = (obj) => {
    setEfectivoPecuarioList(obj);
    setListaEfectivoPecuario(
      listaAldeiaBairros.filter(
        (model) => model.codigo === null || model.live_stock === obj.codigo
      )
    );
  };

  const listDataFromCheckBox = listaTipoCultura;

  const handleSelect = (event) => {
    // const value = event.target.value;
    // const isChecked = event.target.checked;

    // if (isChecked) {
    //   //Add checked item into checkList
    //   setCheckedList([...checkedList, value]);
    // } else {
    //   //Remove unchecked item from checkList
    //   const filteredList = checkedList.filter((item) => item !== value);
    //   setCheckedList(filteredList);
    // }
  };


  const showConfirmBox = () => {
    var nome = "Entidade?";
    return showConfirm ? (
      <ConfirmBox
        msg={StringMessage.message2 + ` ${nome}`}
        handleResult={deleteData}
      />
    ) : null;
  };



  const setOpenNewForm = (state, type = "provincia") => {
    setIsMun(type === "municipio");
    setNewItemOpen(true);
  };

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteEntities(codigo);
      if (result.success) {
        setData();
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessage.messageRemove);
      } else if (!result?.success) {
        showMsg.error(result?.exception?.msg);
      }
    }
    setShowConfirm(false);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
  };

  const saveForm = async () => {
    setDataRegistro(null)
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      email: email,
      contacto: contacto,
      infrastructure_company: companyInfrastucture,
      provincia: provincia?.codigo,
      municipio: municipio?.codigo,
      comuna: comuna?.codigo,
      aldeia_bairro: aldeiaBairro?.codigo,
      nif: nif,
      observacao: observacao,
      tipo_de_exploracao: tipoExploracao?.codigo,
      infrastructure_company: companyInfrastucture?.codigo,
      objecto_social: objectoSocial?.codigo,
      tipo_entidade: tipoEntidade,
      pequeno_produtor: pequenoProdutor,
      prestador: tipoEploraPrestador,
      empresarial: tipoEploracForm,
      // licenca_de_exploracao: licencaDeExploracao,
      formularios: selectedFormularios,
      main_activities: selectedMainActivities,
      entidade_superior: entidadeSuperior?.codigo,
      company_creation_date: compareNullDate(companyCreatedDate),
      data_do_registro: compareNullDate(dataRegistro),
      male_number_employee: numberOfMaleEmployee,
      female_number_employee: numberOfFemaleEmployee,
      manager_contact: managerContact,
      manager_email: managerEmail,
      manager_name: managerName,
      number_static_register: staticRegister,
      number_title_land_conception: titleNumberOfLandConception,
      total_area_cultivated_company: cultiviedCompanyTotalArea,
      total_area_ocuppied_company: occupiedCompanyTotalArea,
      tipos_de_producao_list: tiposDeProducaoList,
      tipos_de_cultura_list: tiposDeCulturaList,
      infraestrutura_de_empresa: infraCompanyList,
      efectivo_pecuario_list: efectivoPecuarioList,
      actividade_principal_list: actividadePrincipalList,
      area_exploracao_curso: areaExploracaoCurso,

    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        // { value: nif, label: "NIF", type: "required" },
        { value: contacto, label: "Contacto", type: "required" },
        { value: contacto, label: "Contacto", type: "number" },
        { value: email, type: "email", label: "email" },
        { value: provincia, label: "Província", type: "required" },
        { value: municipio, label: "Município", type: "required" },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    console.log("*************Savedrop****************", rowData)

    let result = await userApiService.saveEntidade(rowData);
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      showMsg.success(StringMessage.messageSave);
    } else {
      showMsg.error(createErrorMessage(result));
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const UpdateForm = async () => {
    setOpenSpinner(true);

    const rowData = {
      codigo: codigo,
      nome: nome,
      email: email,
      contacto: contacto,
      provincia: provincia?.codigo,
      municipio: municipio?.codigo,
      comuna: comuna?.codigo,
      aldeia_bairro: aldeiaBairro?.codigo,
      nif: nif,
      observacao: observacao,
      tipo_de_exploracao: tipoExploracao?.codigo,
      objecto_social: objectoSocial?.codigo,
      tipo_entidade: tipoEntidade,
      pequeno_produtor: pequenoProdutor,
      prestador: tipoEploraPrestador,
      empresarial: tipoEploracForm,
      // licenca_de_exploracao: licencaDeExploracao,
      formularios: selectedFormularios,
      entidade_superior: entidadeSuperior?.codigo,
      main_activities: selectedMainActivities,
      company_creation_date: companyCreatedDate,
      male_number_employee: numberOfMaleEmployee,
      female_number_employee: numberOfFemaleEmployee,
      manager_contact: managerContact,
      manager_email: managerEmail,
      manager_name: managerName,
      number_static_register: staticRegister,
      number_title_land_conception: titleNumberOfLandConception,
      total_area_cultivated_company: cultiviedCompanyTotalArea,
      total_area_ocuppied_company: occupiedCompanyTotalArea,
      tipos_de_producao_list: tiposDeProducaoList,
      tipos_de_cultura_list: tiposDeCulturaList,
      infraestrutura_de_empresa: infraCompanyList,
      efectivo_pecuario_list: efectivoPecuarioList,
      data_do_registro: dataRegistro,
      actividade_principal_list: actividadePrincipalList,
      area_exploracao_curso: areaExploracaoCurso,

    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        //{ value: email, type: "email", label: "email" },
        { value: contacto, label: "Contacto", type: "required" },
        { value: contacto, label: "Contacto", type: "number" },
        { value: provincia, label: "Província", type: "required" },
        { value: municipio, label: "Município", type: "required" },
        //{ value: nif, label: "NIF", type: "required", size: 11 },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    console.log("*************Updatedrop****************", rowData)
    let result = await userApiService.updateEntidade(rowData);
    let message = StringMessage.messageEditar;
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      showMsg.success(message);
    } else {
      showMsg.error(StringMessage.messageErrorSave + ` entidade`);
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };



  const saveNewItemForm = async () => {
    const toSave = { nome: provName, sigla: provSigla };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: provName, label: "Nome", type: "required" },
      ])
    ) {
      return false;
    }

    var result = null;
    if (!isMun) {
      result = await userApiService.saveProvince(toSave);

    } else {
      toSave.provincia = prov.codigo;
      result = await userApiService.saveMunicipio(toSave);
    }

    if (result?.success) {
      setData();

      handleClose();
      showMsg.success(StringMessage.messageSave);
    } else {
      showMsg.error(result?.exception?.msg);
    }

    getListProv(toSave);
    getListMuni(toSave);
    addItemsPm = result;
    setAddNewItemPM(addItemsPm);

  };

  const data = new Date()
  const day = `${data.getDate()}`.padStart(2, 0)
  // const month = `${data.getMonth() + 1}`.padStart(2, 0)
  let _month = data.toLocaleString('default', { month: 'long' });
  const month = _month[0].toUpperCase() + _month.slice(1);
  const year = data.getFullYear()
  const textContentDate = `${day} DE ${month} DE ${year}`;
  const validoAteDate = `${day} de ${month} de ${year + 1}`;
  //texto sobre registo do GEPE  nao cuidado ao mexer aqui pode estragar o pdf

  const createPDF = async () => {
    try {

      const pdf = new jsPDF("portrait", "pt", "a4");
      const htmlTemplate = document.querySelector(".mostrarPDer");
      htmlTemplate?.classList.remove('d-none');

      var qrCodeContainer = document.getElementById('qr-code-container')
      let qrContent = `${registerNumber}:${nome}`;
      // let qrContent = `Certificado n° ${registerNumber} | Nome da Empresa: ${nome} | Nif: ${nif} | Objecto Social: ${objectoSocial?.nome} | Local de actividade: ${provincia?.nome}`;

      QRCode.toDataURL(qrContent, function (err, data) {
        qrCodeContainer.src = data;
      })

      pdf.html(htmlTemplate, {
        callback: function (doc) {
          // Save the PDF
          htmlTemplate?.classList.add('d-none');
          doc.save(`${capFirstLetter(nome)}.pdf`);
        },
        margin: [10, 10, 10, 10],
        autoPaging: 'text',
        x: 0,
        y: 0,
        width: 260, //target width in the PDF document
        windowWidth: 675 //window width in CSS pixels
      });

      // const data = await html2canvas(htmlTemplate);
      // const img = data.toDataURL("image/png");
      // const imgProperties = pdf.getImageProperties(img);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      // pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      // pdf.save(`${capFirstLetter(nome)}.pdf`);
      // htmlTemplate?.classList.add('d-none');

    } catch (error) {
      console.log("create pdf: ", error)
    }
  };

  const handleClickOpen = () => {
    setNewItemOpen(true);
  };

  const handleClose = () => {
    setNewItemOpen(false);
  };

  const loadTable = async () => {
    let list = (((await userApiService.getEntidades())) ?? []).reverse();
    let rows = [];
    Object.values(list).map((l) => {
      rows.push({
        num: l.codigo,
        nome: l.nome,
        email: l.email,
        nif: l.nif,
        contacto: l.contacto,
        provincia: l.provincia?.nome,
        municipio: l.municipio?.nome,
        entidade: l.entidade_superior?.nome,
        action: (
          <Actions
            page="entidades"
            value={l}
            handleView={handleShow}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ),
      });
    });

    setList({ columns: columns, rows: rows });
    const listaSuperiores = list
      .filter((item) => item.tipo_entidade === "interna" || item.codigo === 1)
      .map((item) => {
        return { codigo: item.codigo, nome: item.nome };
      });

    listaSuperiores.splice(0, 0, { codigo: null, nome: "Selecione" });
    setListSuperiorEntities(listaSuperiores);
  };

  const handleTitle = (params) => {
    const {
      title = "ENTIDADES",
      subtitle = "Cadastrados",
      mapButton = false,
    } = params ? params : {};
    return (
      <Box className={`forms-title-container position-relative`}>
        <h6 className={`forms-title`}>{title}</h6>
        <label className={`forms-sub-title`}>{subtitle}</label>
      </Box>
    );
  };

  const changeNif = async (nif) => {
    try {
      setNif(nif);
      if (openForm == 3) return;

      let rowData = (await userApiService.getDataByBiOrNif(nif)) || {};
      if (!rowData?.nome) return;

      setNome(rowData?.nome || "");
      setContacto(rowData?.contacto || "");
//      setNif(rowData?.nif);
      setEmail(rowData?.email || "");
      setCompanyCreatedDate(rowData?.companyCreatedDate || "");

      let _provincia = rowData?.provincia ?? "";
      let _municipio = rowData?.municipio ?? ""
      let _comuna = rowData?.comuna ?? "";
      let _aldeiaBairro = rowData?.aldeiaBairro ?? "";

      _provincia = listaProvs?.find((a) => a.nome.toLowerCase() === _provincia.toLowerCase() || (a.sigla ?? "").toLowerCase() === _provincia.toLowerCase());
      _municipio = listaMunics?.find((a) => a.nome.toLowerCase() === _municipio.toLowerCase());
      _comuna = listaComunas?.find((a) => a.nome.toLowerCase() === _comuna.toLowerCase());
      _aldeiaBairro = listaAldeiaBairros?.find((a) => a.nome.toLowerCase() === _aldeiaBairro.toLowerCase());

      setProvincia(_provincia || "");
      setMunicipio(_municipio || "");
      setAldeiaBairro(_comuna || "");
      setComuna(_aldeiaBairro || "");

      showMsg.success(`${rowData?.nome} | ${rowData?.email} | ${rowData?.contacto ?? ""} | Provincia: ${rowData.provincia || ""} | Municipio: ${rowData.municipio || ""} | Comuna: ${rowData.comuna || ""} | Aldeia/Bairro: ${rowData.aldeiaBairro || ""}`);

    } catch (error) {

    }
  };

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      default:
        break;
    }
  };

  const isExterna = () => {

    return tipoEntidade === "externa";
  };

  const isInterna = () => {
    return tipoInterna === "interna";
  };

  const isEmpresarial = () => {

    return tipoEploracForm === "empresarial";

  };

  const isPrestador = () => {
    return tipoEploraPrestador === "prestador";
  };

  const isPequenoProdutor = () => {
    return pequenoProdutor === "pequeno_produtor";
  };

  const changeTipo = async (e) => {
    const { value } = e.target;
    setDataRegistro(formatDateInput(new Date()))
    setTipoInterna(value);
    setTipoEntidade(value);
    setTipoExploraPrestador("");
    setPequenoProdutor("")
    setTipoExploraForm("")
  };

  const changeTipoPrestador = async (e) => {
    const { value } = e.target;

    setTipoExploraPrestador(value);
    setPequenoProdutor(value)
    setTipoExploraForm(value)

  };


  // useEffect(() => {
  // }, [tiposDeProducaoList]);
  // useEffect(() => {

  // }, [infraCompanyList]);
  // useEffect(() => {
  // }, [tiposDeCulturaList]);
  // useEffect(() => {
  // }, [efectivoPecuarioList]);

  const addMoreTipoDeProducao = () => {
    let _tiposDeProducao = [...tiposDeProducaoList]
    let newIndex = _tiposDeProducao?.length
    let tipoDeProducao = { id: newIndex, nome: null, tipoProducao: {}, capacidade: 0 }
    _tiposDeProducao.push(tipoDeProducao);
    setTiposDeProducaoList(_tiposDeProducao)
  }
  const addMoreTipoDeCultura = () => {
    let _tiposDeCultura = [...tiposDeCulturaList]
    let newIndex = _tiposDeCultura?.length
    let tipoDeCultura = { id: newIndex, nome: null, tipoCultura: {}, capacidade: 0 }
    _tiposDeCultura.push(tipoDeCultura);
    setTiposDeCulturaList(_tiposDeCultura)
  }
  const addMoreEfectivoPecuario = () => {
    let _efectivosPecuario = [...efectivoPecuarioList]
    let newIndex = _efectivosPecuario?.length
    let efectivoPcuario = { id: newIndex, nome: null, efectivoPecuario: {}, capacidade: 0 }
    _efectivosPecuario.push(efectivoPcuario);
    setEfectivoPecuarioList(_efectivosPecuario)
  }

  // const addMoreActividadePrincipal = () => {
  //   let _actividadePrincipal = [...actividadePrincipalList]
  //   let newIndex = _actividadePrincipal.length
  //   let actividadePrincipals = { id: newIndex, nome: null, provincia: null }
  //   _actividadePrincipal.push(actividadePrincipals);
  //   setActividadePrincipalList(_actividadePrincipal)
  // }

  const addMoreActividadePrincipal = () => {
    let _actividadePrincipal = [...actividadePrincipalList]
    let newIndex = _actividadePrincipal.length
    let actividadePrincipals = { id: newIndex, nome: null, provincia: null }
    _actividadePrincipal.push(actividadePrincipals);
    setActividadePrincipalList(_actividadePrincipal)
    console.log({ actividadePrincipalList })
  }

  const addMoreInfraCompany = () => {
    let _infra_company = [...infraCompanyList]

    let newIndex = _infra_company?.length
    let infraCompany = { id: newIndex, nome: null, infraCompany: {}, capacidade: 0 }
    _infra_company.push(infraCompany);
    setInfraCompanyList(_infra_company)
  }

  const removeActividadePrincipal = (index) => {
    let _actividadePrincipal = [...actividadePrincipalList]
    _actividadePrincipal.splice(index, 1)
    setActividadePrincipalList(_actividadePrincipal)
  }

  const removeTipoDeProducao = (index) => {
    let _tiposDeProducao = [...tiposDeProducaoList]
    _tiposDeProducao.splice(index, 1)
    setTiposDeProducaoList(_tiposDeProducao)

  }




  const removeTipoDeCultura = (index) => {
    let _tiposDeCultura = [...tiposDeCulturaList]
    _tiposDeCultura.splice(index, 1)
    setTiposDeCulturaList(_tiposDeCultura)
  }


  const setListaActividadePrincipalData = (index, tipo, value) => {
    let _actividadePrincipalList = [...actividadePrincipalList]
    let code = compareIndex(index)
    let listaActividade = actividadePrincipalList[code]
    listaActividade[tipo] = value
    if (tipo === "nome") {
      listaActividade["nome"] = value.nome
      listaActividade["listaActividade"] = value
    }
    _actividadePrincipalList.splice(_actividadePrincipalList.indexOf(listaActividade), 1, listaActividade)
    setActividadePrincipalList(_actividadePrincipalList)
  }

  const removeEfectivoPecuario = (index) => {
    let _efectivosPecuario = [...efectivoPecuarioList]
    _efectivosPecuario.splice(index, 1)
    setEfectivoPecuarioList(_efectivosPecuario)
  }
  const removeCompanyInfra = (index) => {
    let _infra_company = [...infraCompanyList]
    _infra_company.splice(index, 1)
    setInfraCompanyList(_infra_company)
  }
  const setTipoDeProducaoData = (index, tipo, value) => {
    let _tiposDeProducao = [...tiposDeProducaoList]
    let code = compareIndex(index)
    let tipoDeProducao = tiposDeProducaoList[code]
    tipoDeProducao[tipo] = value
    if (tipo === "nome") {
      tipoDeProducao["nome"] = value.nome
      tipoDeProducao["tipoProducao"] = value
    }

    _tiposDeProducao.splice(_tiposDeProducao.indexOf(tipoDeProducao), 1, tipoDeProducao)
    setTiposDeProducaoList(_tiposDeProducao)

  }



  const setTipoDeCulturaData = (index, tipo = "nome", value) => {
    // value = JSON.parse(value)

    let _tiposDeCultura = [...tiposDeCulturaList]

    let code = compareIndex(index)

    let tipoDeCultura = tiposDeCulturaList[code]

    tipoDeCultura[tipo] = value
    if (tipo === "nome") {
      tipoDeCultura["nome"] = value.nome
      tipoDeCultura["tipoCultura"] = value
    }

    _tiposDeCultura.splice(_tiposDeCultura.indexOf(tipoDeCultura), 1, tipoDeCultura)
    setTiposDeCulturaList(_tiposDeCultura)
  }

  const setTipoDeCulturaDataCheckbox = (code, tipo = "nome", event) => {
    let value = event.target.value;
    value = JSON.parse(value)

    let _tiposDeCultura = [...tiposDeCulturaList]

    code = compareIndex(code)

    const isChecked = event.target.checked;
    if (isChecked) {

      _tiposDeCultura.push(value)
      setCheckedList(_tiposDeCultura);
      setTiposDeCulturaList(_tiposDeCultura)

    } else {

      console.error('not checkkkkkked');
      _tiposDeCultura = _tiposDeCultura.filter((item) => item.codigo !== value.codigo);
      setCheckedList(_tiposDeCultura);
      setTiposDeCulturaList(_tiposDeCultura)

    }
  }


  useEffect(() => {

  }, [tiposDeCulturaList]);


  const setEfectivoPecuarioData = (index, tipo, value) => {
    let _efectivosPecuario = [...efectivoPecuarioList]
    let code = compareIndex(index)
    let efectivoPcuario = efectivoPecuarioList[code]
    efectivoPcuario[tipo] = value
    if (tipo === "nome") {
      efectivoPcuario["nome"] = value.nome
      efectivoPcuario["efectivoPecuario"] = value
    }
    _efectivosPecuario.splice(_efectivosPecuario.indexOf(efectivoPcuario), 1, efectivoPcuario)
    setEfectivoPecuarioList(_efectivosPecuario)
  }
  const setCompanyInfraData = (index, tipo, value) => {
    let _infra_company = [...infraCompanyList]
    let code = compareIndex(index)
    let companyInfra = infraCompanyList[code]
    companyInfra[tipo] = value
    if (tipo === "nome") {
      companyInfra["nome"] = value.nome
      companyInfra["infraCompany"] = value
    }
    _infra_company.splice(_infra_company.indexOf(companyInfra), 1, companyInfra)
    setInfraCompanyList(_infra_company)

  }

  const getListProv = (toSave) => {
    let oldPorv = listaProvs;
    oldPorv.push(toSave)
    setListaProvs(oldPorv)
    return listaProvs;
  }
  const setProvinciaShowDetails = (actividadePrincipal) => {
    let provCode = actividadePrincipal.provincia.codigo;
    let provinciaName = listaProvs.filter(prov => provCode === prov.codigo);
    console.log(provCode, provinciaName)
    return provinciaName[0].nome

  }

  const getListMuni = (toSave) => {
    let oldMuni = listaMunics;
    oldMuni.push(toSave)
    setListaMunics(oldMuni)
    return listaMunics;
  }

  const handleFormMenus = () => {
    return (
      <Box
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
      >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>ENTIDADES</strong>&nbsp;CADASTRADAS
          </Box>
          <div className="allbtns">
            <AddButton
              page="entidades"
              handleAdd={handleAdd}
              dataTip={"Adicionar Entidade"}
              dataFor={"adicionar"}
              addText={"Adicionar"}
            />
          </div>
        </Box>

        {iframeUrl ? (
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`}>
            {handleTitle({
              title: iframeTitle,
              subtitle: "Preenchimento",
              mapButton: true,
            })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
        ) : (
          <>
            <Box className={`listagem-tabela d-12`}>
              <DATATABLE className={`form-list flex-fill d-12`} data={list} />
            </Box>
          </>
        )}
      </Box>
    );
  };



  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`}>

        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
            <div className="allbtns">
              <Button
                onClick={() => setOpenForm(0)}
                className={`cancel-btn m-1`}
              >
                {back}
              </Button>
              <Button
                onClick={() => handleEdit(currentEntidade)}
                className={`right-btn m-1`}
              >
                editar
              </Button>
            </div>
          </Box>
        </Box>
        <Box
          id={"view-form"}
          className={`listagem-tabela d-flex flex-column p-5 pb-3`}
        >
          <Row className={`d-flex flex-row`}>
            <Col sm={4} className={`d-flex flex-column d-6 `}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Tipo de entidade</strong>
                </FormLabel>
                <FormLabel>{capFirstLetter(tipoEntidade)} {tipoEploracForm && `- ${capFirstLetter(tipoEploracForm)}`}
                </FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Nº Registo Minagrip</strong>
                </FormLabel>
                <FormLabel>{registerNumber === null ? '' : registerNumber}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Nº Filiação</strong>
                </FormLabel>
                <FormLabel>{filiationNumber === null ? '' : filiationNumber}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Nome</strong>
                </FormLabel>
                <FormLabel>{capFirstLetter(nome)}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Email</strong>
                </FormLabel>
                <FormLabel>{email}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>NIF</strong>
                </FormLabel>
                <FormLabel>{nif}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Contacto</strong>
                </FormLabel>
                <FormLabel>{contacto}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Objecto social</strong>
                </FormLabel>
                <FormLabel>{objectoSocial?.nome}</FormLabel>
              </FormControl>
            </Col>
            <Col sm={4} className={`d-flex flex-column d-6 p-3`}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Província</strong>
                </FormLabel>
                <FormLabel>{provincia?.nome}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Município</strong>
                </FormLabel>
                <FormLabel>{municipio?.nome}</FormLabel>
                <FormLabel component="legend">
                  <strong>Bairro</strong>
                </FormLabel>
                <FormLabel>{aldeiaBairro?.nome}</FormLabel>
              </FormControl>
              {entidadeSuperior && (
                <FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-2">
                    <strong>Entidade Superior</strong>
                  </FormLabel>
                  <FormLabel>{entidadeSuperior?.nome}</FormLabel>
                </FormControl>
              )}
              {(companyCreatedDate || staticRegister) && (
                <FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Data Constituição de Empresa</strong>
                  </FormLabel>
                  <FormLabel>{companyCreatedDate}</FormLabel>
                  <FormLabel component="legend">
                    <strong>Nº do Registro Estatistico</strong>
                  </FormLabel>
                  <FormLabel>{staticRegister}</FormLabel>
                </FormControl>
              )}
              {(licencaDeExploracao || titleNumberOfLandConception) && (
                <FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Nº Licença de Exploração Florestal</strong>
                  </FormLabel>
                  <FormLabel>{licencaDeExploracao}</FormLabel>
                  <FormLabel component="legend">
                    <strong>Nº do Titulo de Concessão de Terra</strong>
                  </FormLabel>
                  <FormLabel>{titleNumberOfLandConception}</FormLabel>
                </FormControl>
              )}
              {(occupiedCompanyTotalArea || cultiviedCompanyTotalArea) && (
                <FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Área Total Ocupada pela Empresa</strong>
                  </FormLabel>
                  <FormLabel>{occupiedCompanyTotalArea}</FormLabel>
                  <FormLabel component="legend">
                    <strong>Área Total Cultivada pela Empresa</strong>
                  </FormLabel>
                  <FormLabel>{cultiviedCompanyTotalArea}</FormLabel>
                </FormControl>
              )}
            </Col>
            <Col sm={4} className={`d-flex flex-column d-6 p-3`}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Observações</strong>
                </FormLabel>
                <FormLabel>{observacao}</FormLabel>
              </FormControl>

              {(numberOfMaleEmployee || numberOfFemaleEmployee) &&
                (<FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Nº de Trabalhadores Masculinos</strong>
                  </FormLabel>
                  <FormLabel>{numberOfMaleEmployee}</FormLabel>
                  <FormLabel component="legend">
                    <strong>Nº de Trabalhadores Femininos</strong>
                  </FormLabel>
                  <FormLabel>{numberOfFemaleEmployee}</FormLabel>
                </FormControl>)
              }

              {managerName &&
                (<FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Nome do Gerente</strong>
                  </FormLabel>
                  <FormLabel>{capFirstLetter(managerName)}</FormLabel>
                  <FormLabel component="legend">
                    <strong>Email do gerente</strong>
                  </FormLabel>
                  <FormLabel>{managerEmail}</FormLabel>
                  <FormLabel component="legend">
                    <strong>Contacto do Gerente</strong>
                  </FormLabel>
                  <FormLabel>{managerContact}</FormLabel>
                </FormControl>)
              }

              {dataRegistro &&
                (<FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Data do Registro</strong>
                  </FormLabel>
                  <FormLabel>{dataRegistro}</FormLabel>
                </FormControl>)}

            </Col>

          </Row>
          <Row className={`d-flex flex-column`}>
            <Col sm={12} className={`d-flex flex-column d-12 p-3`}>
              {actividadePrincipalList &&
                (<FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-2">
                    <strong>Principais Actividades</strong>
                  </FormLabel>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    {actividadePrincipalList.map((actividadePrincipal) => {
                      let provincia = setProvinciaShowDetails(actividadePrincipal);
                      console.log("provcodeoppopo", actividadePrincipal)
                      return (
                        <Grid item key={actividadePrincipal.nome}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={actividadePrincipal.nome}
                              secondary={provincia}
                            />
                          </ListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormControl>)}
            </Col>
          </Row>

          <Row className={`d-flex flex-column`}>
            <Col sm={12} className={`d-flex flex-column d-12 p-3`}>
              {efectivoPecuarioList &&
                (<FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-2">
                    <strong>Efectivo Pecuário</strong>
                  </FormLabel>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    {efectivoPecuarioList.map((efectivoPecuario) => {
                      return (
                        <Grid item key={efectivoPecuario.nome}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={efectivoPecuario.nome}
                              secondary={efectivoPecuario.capacidade}
                            />
                          </ListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormControl>)}
            </Col>
          </Row>

          <Row className={`d-flex flex-column`}>
            <Col sm={12} className={`d-flex flex-column d-12 p-3`}>
              {tiposDeCulturaList &&
                (<FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-2">
                    <strong>Tipo de Culturas</strong>
                  </FormLabel>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    {tiposDeCulturaList.map((item) => {
                      return (
                        <Grid item key={item.nome}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={item.nome}
                              secondary={item.modelo}
                            />
                          </ListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormControl>)}
            </Col>
          </Row>

          <Row className={`d-flex flex-column`}>
            <Col sm={12} className={`d-flex flex-column d-12 p-3`}>
              {infraCompanyList &&
                (<FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-2">
                    <strong>Infra Estrutura de Empresa</strong>
                  </FormLabel>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    {infraCompanyList.map((infraCompany) => {
                      return (
                        <Grid item key={infraCompany.nome}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ImageIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={infraCompany.nome}
                              secondary={infraCompany.capacidade}
                            />
                          </ListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormControl>)}
            </Col>
          </Row>

          <Row className={`d-flex flex-column`}>
            <Col sm={12} className={`d-flex flex-column d-12 p-3`}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className="mb-2">
                  <strong>Formulários</strong>
                </FormLabel>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  {listaFormularios.map((form) => {
                    return (
                      <Grid item key={form.nome}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <ImageIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={form.nome}
                            secondary={form.modelo}
                          />
                        </ListItem>
                      </Grid>
                    );
                  })}
                </Grid>
              </FormControl>
            </Col>
          </Row>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row  p-5 pt-1`}>
          <Col sm={12} className={`d-flex flex-column`}>
            <FormControl component="fieldset" className="mb-2">
              <FormLabel>Visualizar informações da entidade</FormLabel>
              <FormLabel className="">
                <i className="fa fa-file"></i>
                <button
                  className="pdfEntidade"
                  // onClick={() => print(`${capFirstLetter(nome)}`, "view-form")}
                  onClick={createPDF}
                >
                  {capFirstLetter(nome)}.pdf
                </button>
              </FormLabel>
            </FormControl>
            <div className={`d-flex flex-row d-6 p-0 pt-1`}>
              <label> <input
                type="checkbox"
                checked={declarou}
                name="TipoCultura"
                value={declarou}
                onChange={(e) => setDeclarou(e.target.checked)}
                className={`select-labels`}
              />
                &nbsp;
                assinala caso a empresa declarar a produção</label>
            </div>
          </Col>
        </Box>

        {/* //gerando documento de pdf */}
        <Row className={`mostrarPDer position-relative p-3 m-1 d-none`}>
          <div id="outerBorder">
            <div id="innerBorder">
              <div id="secondInnerBorder">
                <div class="headerDoc">
                  <div class="headerH1Doc">
                    <h6><img src={angola_logo} alt="" width={`150px`} /></h6>
                    <p>REPÚBLICA DE ANGOLA</p>
                    <p>MINISTÉRIO DA AGRICULTURA E FLORESTAS</p>
                    <p>GABINETE DE ESTUDOS, PLANEAMENTO E ESTATÍSTICA</p>
                    <p>DEPARTAMENTO DE ESTUDOS E ESTATÍSTICA</p>
                  </div>

                  <div class="headerH2Doc">
                    <p>CERTIFICADO DE REGISTO ESTATÍSTICO</p>
                    <p>SECTOR AGRÁRIO Nº {registerNumber}</p>
                  </div>

                </div>

                <div class="contentDoc">
                  <p class="paragraphDoc"> <span class="firstWordDoc">Em</span> cumprimento do Decreto n° 92/82 do Conselho de Ministros, certifico que a Empresa
                    <strong class="negritarRegistos"> {nome.toUpperCase()}</strong>,&nbsp;
                    {staticRegister === null ? null :
                      <span>está registada pelo Instituto Nacional de Estatística sob o número <strong class="negritarRegistos">{staticRegister ?? ''}</strong>&nbsp;e&nbsp;</span>}
                    tem como objectivo o exercício da actividade agrária, especificamente {objectoSocial?.nome}, com sede em (no) <strong class="negritarRegistos">{(provincia?.nome)}</strong>


                    {actividadePrincipalList.length > 0 ?
                      <>
                        <span>&nbsp;e de âmbito territorial, <strong class="negritarRegistos">local:</strong>&nbsp;</span>
                        {actividadePrincipalList.map((item) => {
                          let provincia = setProvinciaShowDetails(item);
                          return (
                            <strong class="negritarRegistos" item key={item.codigo}>{provincia},&nbsp;</strong>)
                        })}
                      </>
                      : <span>,&nbsp;</span>
                    }

                    sob o NIF: <strong class="negritarRegistos">{nif}</strong>.</p>

                  <p class="paragraphDoc"> <span class="firstWordDoc">A</span> empresa declarou {!declarou ? 'não' : ''} estar em actividade produtiva.</p>

                  <p class="paragraphDoc"> <span class="firstWordDoc">Por</span> ser verdade e para constar se passa o presente Certificado que vai assinado
                    Pelo Director do Gabinete de Estudos, Planeamento e Estatística deste Ministério.</p>

                  <p id="DateDoc" class='paragraphDoc'><span class="firstWordDoc">LUANDA,&nbsp;AOS&nbsp;{formatDateDocument(new Date())}.</span> </p>
                </div>

                <div class="footerDoc">
                  <div class="infoFooterDoc">
                    <div class="directorDoc">
                      <p class="bold-text">O DIRECTOR </p>
                      <p>___________________________________</p>
                      <p class="bold-text">{nomeDirectorCong}</p>
                    </div>
                  </div>
                  <div class="qrCodeFooter">
                    <img id="qr-code-container" />
                    <span id="validadeDoc">NOTA: Válido até {validoAteDate}
                      <br />Obs: Versão 2023, com nova ordem numérica.</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Row>
      </Row>
    );
  };

  const editForm = () => {
    return (
      <Row
        className={`listagem position-relative container-fluid h-100 w-100 p-3 m-0 d-none`}
      >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>
            &nbsp;ENTIDADE
          </Box>
          <div className="allbtns">
            <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`}>
              {cancel}
            </Button>
            <Button
              onClick={saveForm}
              className={`right-btn m-1`}
              style={{ display: saveButtonVisible }}
            >
              Salvar
            </Button>
            <Button
              onClick={UpdateForm}
              className={`right-btn m-1`}
              style={{ display: editButtonVisible }}
            >
              Actualizar
            </Button>
          </div>
        </Box>
        <Box className={`listagem-tabela`}>
          <Box className={`d-flex flex-row  p-5`}>
            <Col sm={12} className={`d-flex flex-column d-6 `}>
              <Box className={`d-flex flex-row p-0`}>
              </Box>

              <FormControl component="fieldset">
                <FormLabel component="legend">Tipo de entidade</FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  onChange={changeTipo}
                  value={tipoEntidade}
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="interna"
                    control={<Radio />}
                    label="Interna"
                  />
                  <FormControlLabel
                    value="externa"
                    control={<Radio />}
                    label="Externa"
                  />
                </RadioGroup>
              </FormControl>
              <Row> {isExterna() ? (
                <Col >
                  <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      onChange={changeTipoPrestador}
                      value={tipoEploraPrestador}
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="pequeno_produtor"
                        control={<Radio />}
                        label="Pequeno Produtor"
                      />
                      <FormControlLabel
                        value="prestador"
                        control={<Radio />}
                        label="Prestador de Serviço"
                      />
                      <FormControlLabel
                        value="empresarial"
                        control={<Radio />}
                        label="Empresarial"
                      />
                    </RadioGroup>
                  </FormControl></Col>) : null}</Row>
            </Col>

          </Box>

          <Box className={`d-flex flex-column h-100 p-1`}>
            <Row sm={12} className={`d-flex flex-column d-6 p-3`}>
              <Row sm={12} className={`d-flex flex-row d-6 p-3`}>
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start ${`large-font`} input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Entidade Superior:</label>
                    <SelectLabels
                      initialValue={entidadeSuperior}
                      setValue={handleSetEntidadeSuperior}
                      lista={listSuperiorEntities}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start ${`large-font`} input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Entidade Superior:</label>
                    <SelectLabels
                      initialValue={entidadeSuperior}
                      setValue={handleSetEntidadeSuperior}
                      lista={listSuperiorEntities}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start ${`large-font`} input-container-2 ${isExterna() ? "required" : ""
                      }`}
                  >
                    <label className={`input-label-23`}>
                      Tipo de exploração:
                    </label>
                    <SelectLabels
                      initialValue={tipoExploracao}
                      setValue={setTipoExploracao}
                      lista={listaExploracao}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Objecto Social:</label>
                    <SelectLabels
                      initialValue={objectoSocial}
                      setValue={setObjectoSocial}
                      lista={listaObjectoSocial}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Objecto Social:</label>
                    <SelectLabels
                      initialValue={objectoSocial}
                      setValue={setObjectoSocial}
                      lista={listaObjectoSocial}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row sm={12} className={`d-flex flex-row d-6 p-3`}>
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>BI/NIF:</label>
                    <Input
                      type="text"
                      label="nif"
                      disableUnderline={true}
                      value={nif}
                      onChange={(e) => changeNif(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>BI/NIF:</label>
                    <Input
                      type="text"
                      label="nif"
                      disableUnderline={true}
                      value={nif}
                      onChange={(e) => changeNif(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}

                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Nome:</label>
                    <Input
                      type="select"
                      label="nome"
                      disableUnderline={true}
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Nome:</label>
                    <Input
                      type="select"
                      label="nome"
                      disableUnderline={true}
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Email:</label>
                    <Input
                      type="email"
                      label="email"
                      disableUnderline={true}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Email:</label>
                    <Input
                      type="email"
                      label="email"
                      disableUnderline={true}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />

                  </Col>
                ) : null}



                {/* {isPequenoProdutor()? (
                  <ColBI/NIF:
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>BI/NIF:</label>
                    <Input
                      type="text"
                      label="nif"
                      disableUnderline={true}
                      value={nif}
                      onChange={(e) => setNif(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null} */}

              </Row>
              <Row id="select-provincia">
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <Box className={`d-flex flex-row p-0`}>
                      <label className={`input-label-23`}>Província:</label>
                      <Button
                        onClick={() => setOpenNewForm(false, "provincia")}
                        className={`add-new-btn`}
                      >
                        +
                      </Button>
                    </Box>
                    <SelectLabels
                      initialValue={provincia}
                      select={provincia}
                      setValue={handleProviaUpdate}
                      lista={listaProvs}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <Box className={`d-flex flex-row p-0`}>
                      <label className={`input-label-23`}>Província:</label>
                      <Button
                        onClick={() => setOpenNewForm(false, "provincia")}
                        className={`add-new-btn`}
                      >
                        +
                      </Button>
                    </Box>
                    <SelectLabels
                      initialValue={provincia}
                      select={provincia}
                      setValue={handleProviaUpdate}
                      lista={listaProvs}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <Box className={`d-flex flex-row p-0`}>
                      <label className={`input-label-23`}>Municípios:</label>
                      <Button
                        onClick={() => setOpenNewForm(false, "municipio")}
                        className={`add-new-btn`}
                      >
                        +
                      </Button>
                    </Box>
                    <SelectLabels
                      initialValue={municipio}
                      setValue={handleMunicipUpdate}
                      lista={listaMunicipiosTemp}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <Box className={`d-flex flex-row p-0`}>
                      <label className={`input-label-23`}>Municípios:</label>
                      <Button
                        onClick={() => setOpenNewForm(false, "municipio")}
                        className={`add-new-btn`}
                      >
                        +
                      </Button>
                    </Box>
                    <SelectLabels
                      initialValue={municipio}
                      setValue={handleMunicipUpdate}
                      lista={listaMunicipiosTemp}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                <Col
                  sm={3}
                  className={`d-flex flex-column justify-content-start large-font input-container-2`}
                >
                  <label className={`input-label-23`}>Comuna:</label>
                  <SelectLabels
                    initialValue={comuna}
                    setValue={handleSetComuna}
                    lista={listaComunaTemp}
                    className={`select-labels`}
                  />
                </Col>

                {/* </Col> */}
              </Row>
              <Row className={`flex-row d-6 p-3`}>
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Aldeia/Bairro:</label>
                    <SelectLabels
                      initialValue={aldeiaBairro}
                      setValue={handleComunaUpdate}
                      lista={listaAldeiaBairros}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Aldeia/Bairro:</label>
                    <SelectLabels
                      initialValue={aldeiaBairro}
                      setValue={setAldeiaBairro}
                      lista={listaAldeiaBairros}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                {isInterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Contacto:</label>
                    <Input
                      type="text"
                      label="contacto"
                      disableUnderline={true}
                      value={contacto}
                      onChange={(e) => setContacto(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Contacto:</label>
                    <Input
                      type="text"
                      label="contacto"
                      disableUnderline={true}
                      value={contacto}
                      onChange={(e) => setContacto(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isPrestador() ? (

                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Data Constituição de Empresa</label>
                    <Input
                      type="date"
                      label="nome"
                      disableUnderline={true}
                      value={companyCreatedDate}
                      onChange={(e) => setCompanyCreatedDate(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (

                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Data Constituição de Empresa</label>
                    <Input
                      type="date"
                      label="nome"
                      disableUnderline={true}
                      value={companyCreatedDate}
                      onChange={(e) => setCompanyCreatedDate(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
              </Row>

              <Row>
                {isPrestador() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nome do Gerente:</label>
                    <Input
                      type="text"
                      label="nome do manager"
                      disableUnderline={true}
                      value={managerName}
                      onChange={(e) => setManagerName(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nome do Gerente:</label>
                    <Input
                      type="text"
                      label="nome do manager"
                      disableUnderline={true}
                      value={managerName}
                      onChange={(e) => setManagerName(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isPrestador() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 `}
                  >
                    <label className={`input-label-23`}>Email do gerente:</label>
                    <Input
                      type="text"
                      label="manager email"
                      disableUnderline={true}
                      value={managerEmail}
                      onChange={(e) => setManagerEmail(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 `}>
                    <label className={`input-label-23`}>Email do gerente:</label>
                    <Input
                      type="text"
                      label="manager email"
                      disableUnderline={true}
                      value={managerEmail}
                      onChange={(e) => setManagerEmail(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isPrestador() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 `}
                  >
                    <label className={`input-label-23`}>Contacto do Gerente:</label>
                    <Input
                      type="text"
                      label="nif"
                      disableUnderline={true}
                      value={managerContact}
                      onChange={(e) => setManagerContact(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Contacto do Gerente:</label>
                    <Input
                      type="text"
                      label="nif"
                      disableUnderline={true}
                      value={managerContact}
                      onChange={(e) => setManagerContact(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
              </Row>


              <Row>
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº do Registro Estatistico:</label>
                    <Input
                      type="text"
                      label="numero de registro estatico"
                      disableUnderline={true}
                      value={staticRegister}
                      onChange={(e) => setStaticRegister(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}

                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº Licença de Exploração Florestal:</label>
                    <Input
                      type="text"
                      label="Numero de Licença de Exploração Florestal"
                      disableUnderline={true}
                      value={licencaDeExploracao}
                      onChange={(e) => setLicencaDeExploracao(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}

              </Row>
              <Row>
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº do Titulo de Concessão de Terra:</label>
                    <Input
                      type="text"
                      label="numero do titulo de conceicao de terra"
                      disableUnderline={true}
                      value={titleNumberOfLandConception}
                      onChange={(e) => setTitleOfLandConception(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}

              </Row>
              <Row>
                {isPequenoProdutor() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Área Total Ocupada pela Empresa:</label>
                    <Input
                      type="text"
                      label="tipo de Produção"
                      disableUnderline={true}
                      value={occupiedCompanyTotalArea}
                      onChange={(e) => setOccupiedCompanyTotalArea(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}

                {isPequenoProdutor() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Área Total Cultivada pela Empresa:</label>
                    <Input
                      type="text"
                      label="Área Total Cultivada pela Empresa"
                      disableUnderline={true}
                      value={cultiviedCompanyTotalArea}
                      onChange={(e) => setCultivatedCompanyTotalArea(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (

                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Data  do Registro</label>
                    <Input
                      type="date"
                      label="nome"
                      disableUnderline={true}
                      value={dataRegistro}
                      onChange={(e) => setDataRegistro(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isPrestador() ? (

                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 `}
                  >
                    <label className={`input-label-23`}>Data  do Registro</label>
                    <Input
                      type="date"
                      label="nome"
                      disableUnderline={true}
                      value={dataRegistro}
                      onChange={(e) => setDataRegistro(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isPequenoProdutor() ? (

                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Data  do Registro</label>
                    <Input
                      type="date"
                      label="nome"
                      disableUnderline={true}
                      value={dataRegistro}
                      onChange={(e) => setDataRegistro(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>{isPrestador() ? (
                <Col
                  sm={3}
                  className={`d-flex flex-column justify-content-start large-font input-container-2`}
                >
                  <label className={`input-label-23`}>Nº de Trabalhadores Masculinos:</label>
                  <Input
                    type="number"
                    label="numero de trabalhadores masculinos"
                    disableUnderline={true}
                    value={numberOfMaleEmployee}
                    onChange={(e) => setNumberOfMaleEmployee(e.target.value)}
                    autoCapitalize={"none"}
                    className={`input-2 large-font`}
                  />
                </Col>
              ) : null}

                {isPrestador() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº de Trabalhadores Femininos:</label>
                    <Input
                      type="number"
                      label="numero de trabalhadores Femininos"
                      disableUnderline={true}
                      value={numberOfFemaleEmployee}
                      onChange={(e) => setNumberOfFemaleEmployee(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                {isPequenoProdutor() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº de Trabalhadores Masculinos:</label>
                    <Input
                      type="number"
                      label="numero de trabalhadores masculinos"
                      disableUnderline={true}
                      value={numberOfMaleEmployee}
                      onChange={(e) => setNumberOfMaleEmployee(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isPequenoProdutor() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº de Trabalhadores Femininos:</label>
                    <Input
                      type="number"
                      label="numero de trabalhadores Femininos"
                      disableUnderline={true}
                      value={numberOfFemaleEmployee}
                      onChange={(e) => setNumberOfFemaleEmployee(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row className={`d-flex flex-row  p-3`}>
                {isPrestador() ? (
                  <div className={`d-flex flex-column `}>
                    <label className={`large-font`}>Infra Estrutura de Empresa:</label>
                    <Button variant="contained" onClick={addMoreInfraCompany} className="btn btn-primary ">
                      +  Adicionar({infraCompanyList?.length})
                    </Button>
                    <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                      {
                        infraCompanyList?.map((infraCompany) => {
                          let index = infraCompany?.id
                          if (infraCompany.id === undefined || infraCompany.id === null) {
                            index = infraCompanyList.indexOf(infraCompany)
                            console.log("no id", index)
                          }
                          let codigo = infraCompany.codigo
                          let nome = infraCompany?.nome
                          let companyInfraObj = infraCompany
                          companyInfraObj.nome = nome
                          companyInfraObj.codigo = compareCodeIndex(codigo, index)
                          //delete (companyInfraObj["infrastructure_company"])
                          let capacidade = infraCompany?.capacidade
                          if (companyInfraObj.nome === null) {
                            companyInfraObj = {}
                          }
                          return (
                            <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                              <Col sm={6} className={`d-flex flex-column`}>
                                <SelectLabels
                                  initialValue={companyInfraObj}
                                  setValue={(e) => setCompanyInfraData("nome", e)}
                                  lista={listaCompanyInfrasstructure}
                                  className={`select-labels`}
                                />
                              </Col>
                              <Col sm={4} className={``}>
                                <Input
                                  type="text"
                                  label="capacidade"
                                  disableUnderline={true}
                                  value={capacidade}
                                  onChange={(e) => setCompanyInfraData(index, "capacidade", e.target.value)}
                                  autoCapitalize={"none"}
                                  className={`input-2 large-font`}
                                />
                              </Col>
                              <Col sm={2} className={``}>
                                <Button variant="contained" color="error" onClick={() => removeCompanyInfra(index)}>X</Button>
                              </Col>
                            </Row>
                          )
                        })}
                    </Col>
                    <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                  </div>
                ) : null}
                {isEmpresarial() ? (
                  <div className={`d-flex flex-column `}>
                    <Row>
                      <Col sm={6} className={`d-flex flex-column`}>
                        <label className={`large-font`}>Principais Actividades:</label>
                      </Col>
                      {/* <Col sm={2} className="proAju">
                        <label className={`large-font`}>Provincias:</label>
                      </Col> */}

                    </Row>
                    <Button variant="contained" onClick={addMoreActividadePrincipal} className="btn btn-primary ">
                      +  Adicionar({actividadePrincipalList?.length})
                    </Button>
                    <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                      {
                        actividadePrincipalList.map((actividadePrincipal) => {
                          let index = actividadePrincipal.id
                          if (actividadePrincipal.id === undefined || actividadePrincipal.id === null) {
                            index = actividadePrincipalList.indexOf(actividadePrincipal)
                            console.log("no id", index)
                          }
                          let codigo = actividadePrincipal.codigo
                          let nome = actividadePrincipal.nome
                          let actividadePrincipalObj = actividadePrincipal
                          actividadePrincipalObj.nome = nome
                          actividadePrincipalObj.codigo = compareCodeIndex(codigo, index)
                          console.log('actividadePrincipalObj', actividadePrincipalObj);
                          if (actividadePrincipalObj.nome === null) {
                            actividadePrincipalObj = {}
                          }
                          // console.log('listaActividadePrincipal', listaActividadePrincipal);

                          return (
                            <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                              <Col sm={6} className={`d-flex flex-column`}>
                                <SelectLabels
                                  initialValue={actividadePrincipalObj}
                                  setValue={(e) => setListaActividadePrincipalData(index, "nome", e)}
                                  lista={listaActividadePrincipal}
                                  className={`select-labels w-100`}
                                />
                              </Col>
                              <Col sm={4} className={`d-flex flex-column`}>
                                {/* <Input
                                type="text"
                                label="capacidade"
                                disableUnderline={true}
                                value={capacidade}
                                onChange={(e) => setTipoDeProducaoData(index, "capacidade", e.target.value)}
                                autoCapitalize={"none"}
                                className={`input-2 large-font`}
                              /> */}
                                <SelectLabels
                                  initialValue={actividadePrincipalObj.provincia}
                                  setValue={(e) => setListaActividadePrincipalData(index, "provincia", e)}
                                  lista={listaProvs}
                                  className={`select-labels w-100`}
                                />
                              </Col>
                              <Col sm={2} className={``}>
                                <Button variant="contained" color="error" onClick={() => removeActividadePrincipal(index)}>X</Button>
                              </Col>
                            </Row>
                          )
                        })}
                    </Col>
                    <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                  </div>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Área Total Ocupada pela Empresa:</label>
                    <Input
                      type="text"
                      label="tipo de Produção"
                      disableUnderline={true}
                      value={occupiedCompanyTotalArea}
                      onChange={(e) => setOccupiedCompanyTotalArea(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Área Total Cultivada pela Empresa:</label>
                    <Input
                      type="text"
                      label="Área Total Cultivada pela Empresa"
                      disableUnderline={true}
                      value={cultiviedCompanyTotalArea}
                      onChange={(e) => setCultivatedCompanyTotalArea(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <div className={`d-flex flex-column`}>
                    <label className={`large-font`}>Tipo de Culturas:</label>
                    <Col sm={6} className={`d-flex flex-row d-6 p-0 mt-0 `}>
                      <div className="card-body">
                        {listaTipoCultura.map((item, codigo) => {
                          if (item?.codigo)
                            return (
                              <div key={item.codigo} className={`d-flex flex-row d-6 p-0 pt-1`}>
                                <label> <input
                                  type="checkbox"
                                  checked={hasCultureType(item)}
                                  name="TipoCultura"
                                  value={JSON.stringify(item)}
                                  onChange={(e) => setTipoDeCulturaDataCheckbox(item?.codigo, "nome", e)}
                                  className={`select-labels`}
                                />
                                  &nbsp;&nbsp;
                                  {item.nome}</label>
                              </div>
                            );
                        })}
                      </div>

                    </Col>
                  </div>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Área de Exploração Florestal em Curso:</label>
                    <Input
                      type="number"
                      label="Área de Exploração Florestal em Curso"
                      disableUnderline={true}
                      value={areaExploracaoCurso}
                      onChange={(e) => setAreaExploracaoCurso(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <div className={`d-flex flex-column `}>
                    <label className={`large-font`}>Efectivo Pecuário:</label>
                    <Button variant="contained" onClick={addMoreEfectivoPecuario} className="btn btn-primary ">
                      +  Adicionar({efectivoPecuarioList?.length})
                    </Button>
                    <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                      {

                        efectivoPecuarioList.map((efectivoPecuario) => {
                          let index = efectivoPecuario.id
                          if (efectivoPecuario.id === undefined || efectivoPecuario.id === null) {
                            index = efectivoPecuarioList.indexOf(efectivoPecuario)
                            console.log("no id", index)
                          }
                          let codigo = efectivoPecuario.codigo
                          let nome = efectivoPecuario?.nome
                          let efectivoPecuarioObj = efectivoPecuario
                          efectivoPecuarioObj.codigo = compareCodeIndex(codigo, index)
                          //delete (efectivoPecuarioObj["efectivoPecuario"])
                          let capacidade = efectivoPecuario.capacidade
                          if (efectivoPecuarioObj.nome == null) {
                            efectivoPecuarioObj = {}
                          }

                          console.log("EfectivoPecuarioData", efectivoPecuario, index, efectivoPecuarioObj)
                          return (
                            <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                              <Col sm={6} className={`d-flex flex-column`}>
                                <SelectLabels
                                  initialValue={efectivoPecuarioObj}
                                  setValue={(e) => setEfectivoPecuarioData(index, "nome", e)}
                                  lista={listaEfectivoPecuario}
                                  className={`select-labels w-100`}
                                />
                              </Col>
                              <Col sm={4} className={``}>
                                <Input
                                  type="text"
                                  label="capacidade"
                                  disableUnderline={true}
                                  value={capacidade}
                                  onChange={(e) => setEfectivoPecuarioData(index, "capacidade", e.target.value)}
                                  autoCapitalize={"none"}
                                  className={`input-2 large-font`}
                                />
                              </Col>
                              <Col sm={2} className={``}>
                                <Button variant="contained" color="error" onClick={() => removeEfectivoPecuario(index)}>X</Button>
                              </Col>
                            </Row>
                          )
                        })}
                    </Col>
                    <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                  </div>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº de Trabalhadores Masculinos:</label>
                    <Input
                      type="number"
                      label="numero de trabalhadores masculinos"
                      disableUnderline={true}
                      value={numberOfMaleEmployee}
                      onChange={(e) => setNumberOfMaleEmployee(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <Col
                    sm={3}
                    className={`d-flex flex-column justify-content-start large-font input-container-2`}
                  >
                    <label className={`input-label-23`}>Nº de Trabalhadores Femininos:</label>
                    <Input
                      type="number"
                      label="numero de trabalhadores Femininos"
                      disableUnderline={true}
                      value={numberOfFemaleEmployee}
                      onChange={(e) => setNumberOfFemaleEmployee(e.target.value)}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    />
                  </Col>
                ) : null}
                {isEmpresarial() ? (
                  <div className={`d-flex flex-column`}>
                    <label className={`large-font`}>Infra Estrutura de Empresa:</label>
                    <Button variant="contained" onClick={addMoreInfraCompany} className="btn btn-primary ">
                      +  Adicionar({infraCompanyList?.length})
                    </Button>
                    <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                      {
                        infraCompanyList?.map((infraCompany) => {
                          let index = infraCompany?.id
                          if (infraCompany.id === undefined || infraCompany.id === null) {
                            index = infraCompanyList.indexOf(infraCompany)
                            console.log("no id", index)
                          }
                          let codigo = infraCompany.codigo
                          let nome = infraCompany?.nome
                          let companyInfraObj = infraCompany
                          companyInfraObj.nome = nome
                          companyInfraObj.codigo = compareCodeIndex(codigo, index)
                          // delete (companyInfraObj["infrastructure_company"])
                          let capacidade = infraCompany?.capacidade
                          if (companyInfraObj.nome === null) {
                            companyInfraObj = {}
                          }
                          return (
                            <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                              <Col sm={6} className={`d-flex flex-column`}>
                                <SelectLabels
                                  initialValue={companyInfraObj}
                                  setValue={(e) => setCompanyInfraData(index, "nome", e)}
                                  lista={listaCompanyInfrasstructure}
                                  className={`select-labels`}
                                />
                              </Col>
                              <Col sm={4} className={``}>
                                <Input
                                  type="text"
                                  label="capacidade"
                                  disableUnderline={true}
                                  value={capacidade}
                                  onChange={(e) => setCompanyInfraData(index, "capacidade", e.target.value)}
                                  autoCapitalize={"none"}
                                  className={`input-2 large-font`}
                                />
                              </Col>
                              <Col sm={2} className={``}>
                                <Button variant="contained" color="error" onClick={() => removeCompanyInfra(index)}>X</Button>
                              </Col>
                            </Row>
                          )
                        })}
                    </Col>
                    <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                  </div>
                ) : null}
              </Row>

              <Col sm={12} className={`d-flex flex-row d-6 p-3`}>
                {isInterna() ? (
                  <Col sm={12} className={`d-flex flex-column`}>
                    <label className={`large-font`}>Observação:</label>
                    <textarea
                      value={observacao}
                      className={`user-img`}
                      onChange={(e) => {
                        setObservacao(e.target.value);
                      }}
                      rows="6"
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col sm={12} className={`d-flex flex-column`}>
                    <label className={`large-font`}>Observação:</label>
                    <textarea
                      value={observacao}
                      className={`user-img`}
                      onChange={(e) => {
                        setObservacao(e.target.value);
                      }}
                      rows="6"
                    />
                  </Col>
                ) : null}
              </Col>
              {isPequenoProdutor() ? (
                <div className={`d-flex flex-column`}>
                  <label className={`large-font`}>Infra Estrutura de Empresa:</label>
                  <Button variant="contained" onClick={addMoreInfraCompany} className="btn btn-primary ">
                    +  Adicionar({infraCompanyList?.length})
                  </Button>
                  <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                    {
                      infraCompanyList?.map((infraCompany) => {
                        let index = infraCompany?.id
                        if (infraCompany.id === undefined || infraCompany.id === null) {
                          index = infraCompanyList.indexOf(infraCompany)
                          console.log("no id", index)
                        }
                        let codigo = infraCompany.codigo
                        let nome = infraCompany?.nome
                        let companyInfraObj = infraCompany
                        companyInfraObj.nome = nome
                        companyInfraObj.codigo = compareCodeIndex(codigo, index)
                        //delete (companyInfraObj["infrastructure_company"])
                        let capacidade = infraCompany?.capacidade
                        if (companyInfraObj.nome === null) {
                          companyInfraObj = {}
                        }
                        return (
                          <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                            <Col sm={6} className={`d-flex flex-column`}>
                              <SelectLabels
                                initialValue={companyInfraObj}
                                setValue={(e) => setCompanyInfraData(index, "nome", e)}
                                lista={listaCompanyInfrasstructure}
                                className={`select-labels`}
                              />
                            </Col>
                            <Col sm={4} className={``}>
                              <Input
                                type="text"
                                label="capacidade"
                                disableUnderline={true}
                                value={capacidade}
                                onChange={(e) => setCompanyInfraData(index, "capacidade", e.target.value)}
                                autoCapitalize={"none"}
                                className={`input-2 large-font`}
                              />
                            </Col>
                            <Col sm={2} className={``}>
                              <Button variant="contained" color="error" onClick={() => removeCompanyInfra(index)}>X</Button>
                            </Col>
                          </Row>
                        )
                      })}
                  </Col>
                  <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                </div>
              ) : null}
              {isPequenoProdutor() ? (
                <div className={`d-flex flex-column`}>
                  <label className={`large-font`}>Tipo de Culturas:</label>
                  <Col sm={6} className={`d-flex flex-row d-6 p-0 mt-0 `}>
                    <div className="card-body">
                      {listaTipoCultura.map((item, codigo) => {
                        if (item?.codigo)
                          return (
                            <div key={item.codigo} className={`d-flex flex-row d-6 p-0 pt-1`}>
                              <label> <input
                                type="checkbox"
                                checked={hasCultureType(item)}
                                name="TipoCultura"
                                value={JSON.stringify(item)}
                                onChange={(e) => setTipoDeCulturaDataCheckbox(item?.codigo, "nome", e)}
                                className={`select-labels`}
                              />
                                &nbsp;&nbsp;
                                {item.nome}</label>
                            </div>
                          );
                      })}
                    </div>
                  </Col>
                </div>
              ) : null}
              {isPequenoProdutor() ? (
                <div className={`d-flex flex-column `}>
                  <label className={`large-font`}>Efectivo Pecuário:</label>
                  <Button variant="contained" onClick={addMoreEfectivoPecuario} className="btn btn-primary ">
                    +  Adicionar({efectivoPecuarioList?.length})
                  </Button>
                  <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                    {
                      efectivoPecuarioList.map((efectivoPecuario) => {
                        let index = efectivoPecuario.id
                        if (efectivoPecuario.id === undefined || efectivoPecuario.id === null) {
                          index = efectivoPecuarioList.indexOf(efectivoPecuario)
                          console.log("no id", index)
                        }
                        let codigo = efectivoPecuario.codigo
                        let nome = efectivoPecuario?.nome
                        let efectivoPecuarioObj = efectivoPecuario
                        efectivoPecuarioObj.codigo = compareCodeIndex(codigo, index)
                        //delete (efectivoPecuarioObj["efectivoPecuario"])
                        let capacidade = efectivoPecuario.capacidade
                        if (efectivoPecuarioObj.nome == null) {
                          efectivoPecuarioObj = {}
                        }
                        return (
                          <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                            <Col sm={6} className={`d-flex flex-column`}>
                              <SelectLabels
                                initialValue={efectivoPecuarioObj}
                                setValue={(e) => setEfectivoPecuarioData(index, "nome", e)}
                                lista={listaEfectivoPecuario}
                                className={`select-labels w-100`}
                              />
                            </Col>
                            <Col sm={4} className={``}>
                              <Input
                                type="text"
                                label="capacidade"
                                disableUnderline={true}
                                value={capacidade}
                                onChange={(e) => setEfectivoPecuarioData(index, "capacidade", e.target.value)}
                                autoCapitalize={"none"}
                                className={`input-2 large-font`}
                              />
                            </Col>
                            <Col sm={2} className={``}>
                              <Button variant="contained" color="error" onClick={() => removeEfectivoPecuario(index)}>X</Button>
                            </Col>
                          </Row>
                        )

                      })}
                  </Col>
                  <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                </div>
              ) : null}
              {/* {isPrestador() ? (
                <div className={`d-flex flex-column `}>
                  <label className={`large-font`}>Tipos de Produção:</label>
                  <Button variant="contained" onClick={addMoreTipoDeProducao} className="btn btn-primary ">
                   +  Adicionar({tiposDeProducaoList?.length})
                  </Button>
                  <Col sm={6} className={`d-flex flex-column d-6 p-0 mt-3 required`}>
                    {
                      tiposDeProducaoList.map((tipoProducao) => {
                        let index = tipoProducao.id
                        let codigo = tipoProducao.codigo
                        let nome = tipoProducao.nome
                        let tipoProducaoObj = tipoProducao
                        tipoProducaoObj.nome = nome
                        tipoProducaoObj.codigo = compareCodeIndex(codigo, index)
                        //delete (tipoProducaoObj["tipoProdutor"])
                        let capacidade = tipoProducao.capacidade
                        return (
                          <Row key={index} className={`d-flex flex-row d-6 p-0 pt-2 required`}>
                            <Col sm={6} className={`d-flex flex-column`}>
                              <SelectLabels
                                initialValue={tipoProducaoObj}
                                setValue={(e) => setTipoDeProducaoData(index, "nome", e)}
                                lista={listaTipoProducao}
                                className={`select-labels w-100`}
                              />
                            </Col>
                            <Col sm={4} className={``}>
                              <Input
                                type="text"
                                label="capacidade"
                                disableUnderline={true}
                                value={capacidade}
                                onChange={(e) => setTipoDeProducaoData(index, "capacidade", e.target.value)}
                                autoCapitalize={"none"}
                                className={`input-2 large-font`}
                              />
                            </Col>
                            <Col sm={2} className={``}>
                              <Button variant="contained" color="error" onClick={() => removeTipoDeProducao(index)}>X</Button>
                            </Col>
                          </Row>
                        )
                      })}
                  </Col>
                  <Col sm={6} className={`d-flex flex-column d-6 p-3 required`}></Col>
                </div>
              ) : null} */}
              <Col sm={12} className={`d-flex flex-row d-6 p-3`}>
                {isInterna() ? (
                  <Col sm={12} className={`d-flex flex-column`}>
                    <label className={`large-font`}>Formulários:</label>
                    <DualBoxList
                      options={filteredFormularioOptions}
                      selected={selectedFormularios}
                      onChange={onFormularioSelected}
                    />
                  </Col>
                ) : null}
                {isExterna() ? (
                  <Col sm={12} className={`d-flex flex-column`}>
                    <label className={`large-font`}>Formulários:</label>
                    <DualBoxList
                      options={filteredFormularioOptions}
                      selected={selectedFormularios}
                      onChange={onFormularioSelected}
                    />
                  </Col>
                ) : null}
              </Col>
            </Row>
          </Box>
          <Box>
            <Dialog open={openItemForm} onClose={handleClose}>
              <DialogTitle>Criar</DialogTitle>
              <DialogContent>
                <Col
                  sm={12}
                  className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                >
                  <label className={`input-label-23`}>Nome:</label>
                  <Input
                    type="select"
                    label="provincia"
                    disableUnderline={true}
                    value={provName}
                    onChange={(e) => setProvName(e.target.value)}
                    autoCapitalize={"none"}
                    className={`input-2 large-font`}
                  />
                </Col>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} className={`cancel-btn m-1`} >Cancel</Button>
                <Button onClick={saveNewItemForm} className={`right-btn m-1`}>Salvar</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openItemForm} onClose={handleClose}>
              <DialogTitle>Criar</DialogTitle>
              <DialogContent>
                {isMun ? (
                  <Col
                    sm={12}
                    className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                  >
                    <label className={`input-label-23`}>Província:</label>
                    <SelectLabels
                      initialValue={prov}
                      setValue={setProv}
                      lista={listaProvs}
                      className={`select-labels`}
                    />
                  </Col>
                ) : null}
                <Col
                  sm={12}
                  className={`d-flex flex-column justify-content-start large-font input-container-2 required`}
                >
                  <label className={`input-label-23`}>Nome:</label>
                  <Input
                    type="select"
                    label="provincia"
                    disableUnderline={true}
                    value={provName}
                    onChange={(e) => setProvName(e.target.value)}
                    autoCapitalize={"none"}
                    className={`input-2 large-font`}
                  />
                </Col>
                <Col
                  sm={12}
                  className={`d-flex flex-column justify-content-start large-font input-container-2`}
                >
                  <label className={`input-label-23`}>Sigla:</label>
                  <Input
                    type="text"
                    label="sigla"
                    disableUnderline={true}
                    value={provSigla}
                    onChange={(e) => setProvSigla(e.target.value)}
                    autoCapitalize={"none"}
                    className={`input-2 large-font`}
                  />
                </Col>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} className={`cancel-btn m-1`} >Cancel</Button>
                <Button onClick={saveNewItemForm} className={`right-btn m-1`}>Salvar</Button>
              </DialogActions>
            </Dialog>
          </Box>

        </Box>
      </Row>



    );

  };

  return start();
}
